<template>
  <div class="btn day-night">
      <b-form-checkbox switch size="lg" v-model="darkMode"></b-form-checkbox>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "dayNight",
  data() {
    return {
      DayNightKey: 'sun',
      darkMode: false,
    };
  },
  methods:{
    ...mapMutations(['_setIsDayNight']),
  },
  mounted: function () {
    this.darkMode = localStorage.getItem(this.DayNightKey) === "true";
  },
  watch: {
    darkMode (value) {
      if (value == this.IsDayNight)
        return ;
      this._setIsDayNight(value);
      localStorage.setItem(this.DayNightKey, value);
    },
  },
  computed: {
    ...mapState(['WebSetting','IsDayNight']),
  }
};
</script>
