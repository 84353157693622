<template>
  <div class="idxgame hotpro-row idxGame05">
      <b-tabs class="mb-3">
        
        <b-tab :title="$t('store.s_hot')">
          <div class="idg_title_0"></div>
          <div class="idg_relate">
            <p v-for="(item, index) in idxContact" :key="index">{{ item.hot }}</p>
          </div>
          <div class="idg_content">
            <div class="idg_btn">
              <template v-for="Item in HotGameList">
                <a @click="ToUrl(Item.Url, Item.Id)" :class="`idg_list product_${Item.Id} ${Item.Maintain}`">
                  <span>{{ Item.Name }}</span>
                  <i :class="`idg_logo product_${Item.Id}`"></i>
                </a>
              </template>
            </div>
            <a href="#" class="idg_img">
              <img :src="require(`@/website/${DEF_webStyle}/images/icon/hotpro_hot.webp`)" class="img-fluid">
            </a>
          </div>
        </b-tab>
        <b-tab v-for="(item, index) in Seo" :key="index">
          <template v-slot:title>
            <span>{{ item.title }}</span>
          </template>
          <div :class="`idg_title_${item.id}`"></div>
          <div class="idg_relate" v-html="item.description"></div>
          <div class="idg_content">
            <div class="idg_btn">
              <template v-for="link in Product(item.id)" v-if="item.id < 6">
                  <a @click="ToUrl(item.url, link.Id)" :class="`idg_list product_${link.Id} ${link.Maintain}`" :data-text="$t('store.uphold_ing')">
                    <span>{{ link.GameName }}</span>
                    <i :class="`idg_logo product_${link.Id}`"></i>
                  </a>
              </template>
              <template v-if="item.id === 6" >
                <a href="/game/fish" class="idg_list product_10">
                  <span>{{ $t("store.fish_GR") }}</span>
                  <i class="idg_logo product_10"></i>
                </a>
                <a href="/game/fish" class="idg_list product_26">
                  <span>{{ $t("store.fish_OB") }}</span>
                  <i class="idg_logo product_26"></i>
                </a>
                <a href="/game/fish" class="idg_list product_45">
                  <span>{{ $t("store.fish_RSG") }}</span>
                  <i class="idg_logo product_45"></i>
                </a>
                <a href="/game/fish" class="idg_list product_46">
                  <span>{{ $t("store.fish_ZG") }}</span>
                  <i class="idg_logo product_46"></i>
                </a>
              </template>
            </div>
            <nuxt-link class="idg_img" :to="item.url" :title="item.title">
              <img :src="item.image" :alt="item.title" class="img-fluid">
            </nuxt-link>
          </div>
        </b-tab>
      </b-tabs>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  export default {
    data() {
      return {
        idxContact: null,
        hotGame: null,
        list: [],
      };
    },
    created: function () {
      if (this.idxContact == null)
        this.idxContact = getJsonFile(process.env.DEF_webStyle, 'idxContact');
      if (this.hotGame == null)
        this.hotGame = getJsonFile(process.env.DEF_webStyle, 'hotGame');
    },
    methods: {
      ToUrl(Url, ProductId) {
        let GoReplace = { path: Url, query: { ProductId }};
        this.$router.replace(GoReplace);
      },
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      HotGameList() {
        if (!this.hotGame.length || this.hotGame.length == 0)
          return [];

        let Data = [];
        this.hotGame.forEach(Item => {
          let CategoryData = this.MobileMeun.find(Element => Element.id === Item.category_id);
          if (!CategoryData)
            return;

          let ProductData = CategoryData.tabs.find(Element => Element.Id === Item.product_id);
          if (!ProductData)
            return;

          Data.push({
            Url: CategoryData.url,
            Id: ProductData.Id,
            Maintain: ProductData.Maintain,
            Name: ProductData.GameName
          });
        });

        return Data;
      },
      ...mapState(['WebSetting']),
      ...mapGetters(["GameMeun", "Product", "Seo", "MobileMeun"]),
    },
  };
</script>